<template>
  <v-fade-transition
    @fadein="$emit('loader:show')"
    @fadeout="$emit('loader:hide')"
  >
    <div
      v-show="show"
      class="v-spinner-loader"
      :style="styles"
    >
      <font-awesome-icon icon="circle-notch" spin size="2x" />
    </div>
  </v-fade-transition>
</template>

<script>
import VFadeTransition from '@/transitions/VFadeTransition.vue'

export default {
  name: 'VSpinnerLoader',

  props: {
    show: Boolean,
    bgColor: {
      type: String,
      default: 'rgba(6, 6, 6, .75)'
    },
    absolute: Boolean
  },

  model: {
    prop: 'show',
    event: 'change'
  },

  computed: {
    styles () {
      return {
        position: this.absolute ? 'absolute' : 'fixed',
        backgroundColor: this.bgColor
      }
    }
  },

  components: {
    VFadeTransition
  }
}
</script>

<style lang="scss">
@import './VSpinnerLoader.scss';
</style>
