<template>
  <div class="image logo">
    <div class="image--wrapper">
      <img src="@/assets/logo.png" alt="MONOMAX">
    </div>
  </div>
</template>

<script>
export default {
  name: 'VLogo'
}
</script>

<style lang="scss">
.logo {
  position: fixed;
  top: $spacer * 3;
  right: $spacer * 3;
  width: $logo-width;
  opacity: .3;
  z-index: 9999;

  .image--wrapper {
    padding-top: 25%;
    background-color: transparent;
  }
}
</style>
