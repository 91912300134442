<template>
  <router-link
    v-slot="{ navigate }"
    :to="{
      name: 'people',
      params: { slug: this.people.slug }
    }"
    custom
  >
    <div
      v-sn-focusable
      class="v-carousel--slide--link peopleitem"
      data-sn-autofocus="disable"
      @click="navigate"
      @keypress.enter="navigate"
      role="link"
    >
      <div class="avatar avatar--rounded d-block">
        <div class="avatar--container">
          <div class="image avatar--image">
            <div class="image--wrapper">
              <img
                class="img-lazy"
                :data-lazy="people.image_url"
                :alt="$t('profile.labels.avatar_alt', { name: people.title })"
              >
            </div>
          </div>
          <div class="avatar--caption">
            {{ people.title }}
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'PeopleItem',

  props: {
    index: Number,
    people: Object
  }
}
</script>
