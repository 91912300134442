var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{attrs:{"to":{
    name: 'product',
    params: { id: this.clip.product.id }
  },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ navigate }){return [_c('v-card',{directives:[{name:"sn-focusable",rawName:"v-sn-focusable"}],staticClass:"v-carousel--slide--link clipitem",attrs:{"data-sn-autofocus":"disable","role":"link"},nativeOn:{"sn:active":function($event){return _vm.handleActive.apply(null, arguments)},"sn:deactive":function($event){return _vm.handleDeactive.apply(null, arguments)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return navigate.apply(null, arguments)},"click":function($event){return navigate.apply(null, arguments)}}},[_c('div',{staticClass:"clipitem--preview"},[_c('div',{staticClass:"clipitem--preview--video"},[(_vm.activePlayer)?_c('v-player',{ref:"player",attrs:{"shakaOptions":{
            streaming: { preferNativeHls: true }
          },"ui":false,"playlists":[{
            sources: [{
              streamURL: _vm.clip.stream_url,
              streamMimeType: 'application/x-mpegurl'
            }]
          }],"autoplay":""},on:{"player:play":_vm.handleVideoPlay,"player:ended":_vm.handleVideoEnded,"player:error":function($event){_vm.activePlayer = false}}}):_vm._e()],1),_c('v-fade-transition',{on:{"fadein":function($event){_vm.activePlayer = false}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showVideo),expression:"!showVideo"}],staticClass:"image clipitem--preview--image"},[_c('div',{staticClass:"image--wrapper"},[_c('img',{staticClass:"img-lazy",attrs:{"data-lazy":_vm.clip.thumbnail,"alt":_vm.clip.title}})]),_c('div',{staticClass:"image--icon"},[_c('font-awesome-icon',{attrs:{"icon":"circle-play"}})],1)])])],1),_c('div',{staticClass:"v-card--title px-0 py-2"},[_vm._v(" "+_vm._s(_vm.clip.title)+" ")])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }