<template>
  <v-button
    class="v-player--button--video-settings"
    hoverColor="primary"
    rounded
    :disabled="!active"
    @click="handleClick"
  >
    <span class="v-btn--icon"></span> {{ $t(label) }}
  </v-button>
</template>

<script>
import VButton from '@/components/VButton'

export default {
  name: 'VideoSettingsButton',

  data () {
    return {
      active: false,
      label: 'player.buttons.video_settings'
    }
  },

  components: {
    VButton
  },

  methods: {
    handleClick (e) {
      const player_ = this.$parent

      player_.pause()
      player_.showVideoSettings = true

      this.$emit('click', e)
    },

    setTextDisplay (label) {
      this.label = label
    },

    enable (active) {
      this.active = active
      this.$emit('active', active)
    }
  }
}
</script>
