<template>
  <v-button
    class="v-player--button--next-episode"
    hoverColor="primary"
    rounded
    :disabled="!active"
    @keypress.enter.native.stop
    @click="handleClick"
  >
    <span class="v-btn--icon"></span> {{ $t('player.buttons.next_episode') }}
  </v-button>
</template>

<script>
import VButton from '@/components/VButton'

export default {
  name: 'NextEpisodeButton',

  data () {
    return {
      active: false
    }
  },

  components: {
    VButton
  },

  mounted () {
    this.$parent.$on('player:playlistonload', this.handleDisplay)
  },

  beforeDestroy () {
    this.$parent.$off('player:playlistonload', this.handleDisplay)
  },

  methods: {
    handleDisplay () {
      const player_ = this.$parent
      const { index, playlists } = player_.playback
      const lastIndex = playlists.length - 1
      const active = playlists.length > 1 && index < lastIndex

      if (active === this.active) {
        return
      }

      this.active = active
      this.$emit('active', active)

      !active && player_.$el.focus()
    },

    handleClick (e) {
      const player_ = this.$parent

      if (!player_.isVideoPaused) {
        player_.userActive(true)
      }

      player_.nextEpisode()

      this.$emit('click', e)
    }
  }
}
</script>
