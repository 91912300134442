<template>
  <router-link
    v-slot="{ navigate }"
    :to="page"
    custom
  >
    <div
      v-sn-focusable
      class="v-carousel--slide--link moreitem"
      data-sn-autofocus="disable"
      @click="navigate"
      @keypress.enter="navigate"
      role="link"
    >
      <div class="moreitem--container">
        <div class="moreitem--icon">
          <font-awesome-icon icon="arrow-right" />
        </div>
        <div class="moreitem--content">
          {{ $t('labels.view_more') }}
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'MoreItem',

  props: {
    page: Object
  }
}
</script>
