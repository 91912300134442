<template>
  <v-button
    class="v-player--button--back"
    hoverColor="primary"
    rounded
    @click="handleClick"
  >
    <span class="v-btn--icon"></span> {{ $t('player.buttons.back') }}
  </v-button>
</template>

<script>
import VButton from '@/components/VButton'

export default {
  name: 'BackButton',

  components: {
    VButton
  },

  methods: {
    handleClick (e) {
      this.$router.back()
      this.$emit('click', e)
    }
  }
}
</script>
